// TODO: hooks
import { Outlet, Navigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
// NOTE: components
import NavBar from './navbar/NavBar'
import StaffInvitation from '../staffInvitation/StaffInvitation'
// NOTE: images
import { PiHouseFill as HomeIcon } from 'react-icons/pi'
import { PiHouse as HomeOutlineIcon } from 'react-icons/pi'
import { HiMiniHeart as HeartIcon } from 'react-icons/hi2'
import { HiOutlineHeart as HeartOutlineIcon } from 'react-icons/hi2'
import { IoCompassSharp as ExploreIcon } from 'react-icons/io5'
import { IoCompassOutline as ExploreOutlineIcon } from 'react-icons/io5'
import { BsPeopleFill as PeopleIcon } from 'react-icons/bs'
import { BsPeople as PeopleOutlineIcon } from 'react-icons/bs'

/**
 * Display the page with the appropriate navbar and perform actions common to all the pages in the different dashboards
 * 	Staff dashboard: check if they were invited to join a company
 * 	Manager dashboard: check if the company context needs to be updated
 *
 * @param {String} links - links to display on the navbar
 * @param {String} home - link to the home page of the dashboard
 * @param {String} icon - icon to display at the top of the desktop navbar
 */

const Layout = () => {
	const { user } = useAuthContext()

	// NOTE: redirect login page
	if (!user) {
		const prevSearch = window.location.search
		const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`

		return (
			<Navigate
				to={{
					pathname: '/auth',
					search: search
				}}
			/>
		)
	}

	const navLinks = [
		{
			to: '/',
			text: 'Home',
			activeIcon: <HomeIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />,
			inactiveIcon: <HomeOutlineIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />
		},
		{
			to: '/health',
			text: 'Health',
			activeIcon: <HeartIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />,
			inactiveIcon: <HeartOutlineIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />
		},
		{
			to: '/explore',
			text: 'Explore',
			activeIcon: <ExploreIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />,
			inactiveIcon: <ExploreOutlineIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />
		}
	]

	// NOTE: add community page link when they have joined a team
	if (user && user.team)
		navLinks.push({
			to: '/community',
			text: 'Community',
			activeIcon: <PeopleIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />,
			inactiveIcon: <PeopleOutlineIcon className="mx-auto h-auto w-[34px] lg:mx-0 lg:w-[30px]" />
		})

	return (
		<>
			<StaffInvitation />
			<div className="flex min-h-screen min-h-svh">
				<NavBar links={navLinks} />

				<Outlet />
			</div>
		</>
	)
}

export default Layout
