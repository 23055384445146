// TODO: move this file to the component that's using it - it might be used in different places so keep it here

// TODO: rename this context to userDetails to make it more specific

import { createContext, useReducer, useEffect } from 'react'
import * as snomedApi from '../api/snomed/snomed'

export const AuthContext = createContext()

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
	user,
	gender: ''
}

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialState)

	// NOTE: get biological sex, used to determine the product on buy now links
	const fetchSex = async () => {
		try {
			const response = await fetch(`/api/health/api/medical/latest/${user._id}/429019009`, {
				headers: {
					Authorization: `Bearer ${user.token}`
				}
			})

			const json = await response.json()

			if (!json) return dispatch({ type: 'SET_GENDER', payload: '' })

			if (!response.ok) throw Error(json.error)

			const readableSex = snomedApi.getBiologicalSexName(json.value)
			dispatch({ type: 'SET_GENDER', payload: readableSex })
		} catch (error) {
			console.error(`Couldn't load your biological sex: ${error.message}`)
		}
	}

	// NOTE: on first load if user has local stored credentials, log them in and get their additional data
	useEffect(() => {
		// TODO: check this as it's not affecting the code, I think because the routing is already doing this
		if (user) {
			dispatch({ type: 'LOGIN', payload: user })
			fetchSex()
		}
	}, [])

	return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>
}

export const authReducer = (state, action) => {
	switch (action.type) {
		case 'SET_GENDER':
			return { ...state, gender: action.payload }
		case 'UPDATE_COMPANY':
			const updatedCompany = {
				...state.user,
				team: action.payload
			}
			localStorage.setItem('user', JSON.stringify(updatedCompany))
			return { ...state, user: updatedCompany }
		case 'UPDATE_COMPANY_NAME':
			const updatedCompanyName = {
				...state.user,
				team: { ...state.user.team, name: action.payload }
			}
			localStorage.setItem('user', JSON.stringify(updatedCompanyName))
			return { ...state, user: updatedCompanyName }
		case 'UPDATE_COMPANY_LOGO': // NOTE: update the current user's company logo - on uploading new logo
			const updatedCompanyLogo = {
				...state.user,
				team: { ...state.user.team, companyLogo: action.payload }
			}
			localStorage.setItem('user', JSON.stringify(updatedCompanyLogo))
			return { ...state, user: updatedCompanyLogo }
		case 'UPDATE_PROFILE_PIC': // NOTE: update the users profile picture - on uploading new profile picture
			const updatedProfilePicture = { ...state.user, profilePicture: action.payload }
			localStorage.setItem('user', JSON.stringify(updatedProfilePicture))
			return { ...state, user: updatedProfilePicture }
		case 'LOGIN': // NOTE: add the current user document to the context when logging in
			return { ...state, user: action.payload }
		// TODO: clean company object and companyLogo on LOGOUT
		case 'LOGOUT': // NOTE: clean the user field when logging out to remove the data
			return { ...state, user: '', gender: '' }
		default:
			return state
	}
}
