// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
// NOTE: components
import Layout from '../components/ui/Layout'
import Loading from '../components/ui/Loading'
import SubPage from '../components/ui/SubPage'

// NOTE: main pages
const Home = lazy(() => import('./home/Home'))
const Explore = lazy(() => import('./explore/Explore'))
const Onboarding = lazy(() => import('./onboarding/Onboarding'))
const Community = lazy(() => import('./community/Community'))
// NOTE: health page
const Goals = lazy(() => import('./plan/Plan'))
const Calendar = lazy(() => import('./calendar/Calendar'))
const Analytics = lazy(() => import('./analytics/Analytics'))
const Lifestyle = lazy(() => import('./lifestyle/Lifestyle'))
const Specialists = lazy(() => import('./specialists/Specialists'))
// NOTE: assessments
const LifestyleAssessment = lazy(() => import('./assessments/Lifestyle'))
// NOTE: account
const Profile = lazy(() => import('./profile/Profile'))
const Preferences = lazy(() => import('./preferences/Preferences'))
const Setup = lazy(() => import('./setup/Setup'))

const accountLinks = [
	{
		to: 'profile',
		text: 'Profile'
	},
	{
		to: 'preferences',
		text: 'Preferences'
	},
	{
		to: 'setup',
		text: 'Setup'
	}
]

const planLinks = [
	{
		to: 'analytics',
		text: 'Analytics'
	},
	{
		to: 'plan',
		text: 'Plan'
	},
	{
		to: 'calendar',
		text: 'Calendar'
	},
	{
		to: 'lifestyle',
		text: 'Lifestyle'
	},
	{
		to: 'specialists',
		text: 'Specialists'
	}
]

const LoadPage = ({ page }) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const dashboardRoutes = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				index: true,
				element: <LoadPage page={<Home />} />
			},
			{
				path: 'health',
				element: <SubPage title="Health" links={planLinks} />,
				children: [
					{
						index: true,
						element: <Navigate to="analytics" />
					},
					{
						path: 'analytics',
						element: <LoadPage page={<Analytics />} />
					},
					{
						path: 'plan',
						element: <LoadPage page={<Goals />} />
					},
					{
						path: 'calendar',
						element: <LoadPage page={<Calendar />} />
					},
					{
						path: 'lifestyle',
						element: <LoadPage page={<Lifestyle />} />
					},
					{
						path: 'specialists',
						element: <LoadPage page={<Specialists />} />
					}
				]
			},
			{
				path: 'explore',
				element: <LoadPage page={<Explore />} />
			},
			{
				path: 'community',
				element: <LoadPage page={<Community />} />
			},
			{
				path: 'account',
				element: <SubPage title="Account" links={accountLinks} />,
				children: [
					{
						index: true,
						element: <Navigate to="profile" />
					},
					{
						path: 'profile',
						element: <LoadPage page={<Profile />} />
					},
					{
						path: 'preferences',
						element: <LoadPage page={<Preferences />} />
					},
					{
						path: 'setup',
						element: <LoadPage page={<Setup />} />
					}
				]
			},
			{
				path: 'health/assessments/lifestyle',
				element: <LoadPage page={<LifestyleAssessment />} />
			}
		]
	},
	{
		// TODO: fix this as it's rendered without checking if user is authenticated - leave as it is for now and fix when implementing auth.js
		path: '/onboarding',
		element: <LoadPage page={<Onboarding />} />
	}
]

export default dashboardRoutes
