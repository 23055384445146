import { Outlet } from 'react-router-dom'
import Container from './Container'
import PageTitle from './PageTitle'
import { NavLink } from 'react-router-dom'
// components
import { Carousel, SwiperSlide } from './carousel/Carousel'

const SubPage = ({ title, src, links }) => {
	return (
		<Container className="py-4 md:py-6">
			<PageTitle title={title} src={src} className="!mb-2 md:!mb-4" />

			<Carousel className="mb-4">
				{links.map((link, index) => (
					<SwiperSlide style={{ width: 'fit-content', minWidth: 'fit-content' }} key={index}>
						<NavLink to={link.to} className="mr-4 flex w-fit flex-col">
							{({ isActive }) => (
								<>
									<h2
										className={`${isActive ? 'text-slate-600' : 'text-slate-500'} text-2xl font-medium`}
									>
										{link.text}
									</h2>
									<div
										className={`${isActive ? 'bg-slate-600' : 'bg-slate-400'} h-[2.5px] w-full rounded-2xl`}
									/>
								</>
							)}
						</NavLink>
					</SwiperSlide>
				))}
			</Carousel>

			<Outlet />
		</Container>
	)
}

export default SubPage
