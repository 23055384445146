// TODO: use react-query/tRPC/make this a hook

// NOTE: bloodKits.js file with all the fetches made to TWD-HEALTH backend related to blood kits
const bloodKitsEndpoint = '/api/health/api/bloodKits'

// NOTE: Get all non-archived blood kits for a specific user
const getKits = async (userId: string, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!userId) throw Error('No user ID provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/${userId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	})
}

// NOTE: Get the latest processed blood test
const getLatest = async (userId: string, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!userId) throw Error('No user ID provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/latest/${userId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	})
}

// NOTE: Register a new blood kit
const registerKit = async (data: object, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!data) throw Error('No blood kit data provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
}

// NOTE: Update an existing blood kit based on its ID
const updateKit = async (kitId: string, data: object, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!kitId) throw Error('No blood kit ID provided')
	if (!data) throw Error('No blood kit data provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/edit/${kitId}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
}

// NOTE: Add insights (video URL and/or markdown) to a blood kit
const addInsights = async (kitId: string, data: object, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!kitId) throw Error('No blood kit ID provided')
	if (!data) throw Error('No insights data provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/insights/${kitId}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
}

// NOTE: Mark a blood kit as viewed after the user watches the video
const watchedVideo = async (kitId: string, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!kitId) throw Error('No blood kit ID provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/watched/${kitId}`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	})
}

// NOTE: "Delete" a blood kit by setting its archived flag to true
const archiveKit = async (kitId: string, token: string) => {
	// NOTE: throw error if any of the fields needed for the fetch are not provided
	if (!kitId) throw Error('No blood kit ID provided')
	if (!token) throw Error('No token provided')

	return await fetch(`${bloodKitsEndpoint}/${kitId}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	})
}

export { getKits, registerKit, updateKit, addInsights, watchedVideo, archiveKit, getLatest }
