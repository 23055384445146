// TODO: use react-query and create the fetches in an api folder
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNotification } from '../../hooks/useNotification.ts'
import { useAuthContext } from '../../hooks/useAuthContext.js'

const useStaffInvitations = () => {
	const { notify } = useNotification()
	const [urlParams, setUrlParams] = useSearchParams()
	const { user, dispatch } = useAuthContext()
	const [invitations, setInvitations] = useState()
	const [invitation, setInvitation] = useState()
	const [loading, setLoading] = useState('INITIAL') // INITIAL, ACCEPTING, DECLINING
	const [show, setShow] = useState(false)
	const [index, setIndex] = useState()

	const close = () => {
		setShow(false)

		if (invitations.length > index + 1) {
			setTimeout(() => {
				setIndex(index + 1)
				setInvitation(invitations[index + 1])
				setShow(true)
			}, 500) // wait 500ms to open next modal for a smooth transition
		} else {
			// remove token search
			urlParams.delete('teamToken')
			setUrlParams(urlParams)
		}
	}

	const getStaffInvitations = async () => {
		const response = await fetch(`/api/health/api/teams/staff/invitations/${user.email}`, {
			headers: {
				Authorization: `Bearer ${user.token}`
			}
		})

		const json = await response.json()

		if (!response.ok) return notify('danger', `Couldn't load your invitations: ${json.error}`)

		// get teamToken search
		const token = urlParams.get('teamToken')

		// if token exists, get team through fetch function
		let tokenJson
		if (token) {
			const tokenResp = await fetch(`/api/health/api/teams/token/${token}`, {
				headers: {
					Authorization: `Bearer ${user.token}`
				}
			})

			tokenJson = await tokenResp.json()

			if (!tokenResp.ok) return notify('danger', `Couldn't load your token: ${tokenJson.error}`)
		}

		setInvitations(json)

		// a manager added them to a team
		if (json?.length > 0) {
			// if they also have a direct link invitation added to the end of the invitations
			setInvitations(tokenJson ? [...json, tokenJson] : json)
			setShow(true)
			setIndex(0)
			setInvitation(json[0])
		}

		// no invitations but a direct link
		if ((!json || json.length === 0) && tokenJson) {
			setInvitations([tokenJson])
			setShow(true)
			setIndex(0)
			setInvitation(tokenJson)
		}
	}

	const accept = async () => {
		setLoading('ACCEPTING')

		const response = await fetch(`/api/health/api/teams/staff/join`, {
			method: 'PATCH',
			body: JSON.stringify({
				staff: user.email,
				team: invitation._id
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user.token}`
			}
		})

		const json = await response.json()

		setLoading('INITIAL')

		if (!response.ok) return notify('danger', `Couldn't accept invitation: ${json.error}`)

		const updatedUser = {
			...user,
			team: json.team
		}
		localStorage.setItem('user', JSON.stringify(updatedUser))
		dispatch({
			type: 'LOGIN',
			payload: updatedUser
		})

		urlParams.delete('teamToken')
		setUrlParams(urlParams)

		window.location.reload()

		//close()
	}

	const decline = async () => {
		setLoading('DECLINING')

		const response = await fetch(`/api/health/api/teams/staff/decline`, {
			method: 'PATCH',
			body: JSON.stringify({
				staff: user.email,
				team: invitation._id
			}),
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user.token}`
			}
		})

		const json = await response.json()
		setLoading('INITIAL')

		if (!response.ok) return notify('danger', `Couldn't decline invitation: ${json.error}`)

		close()
	}

	useEffect(() => {
		if (!user) return
		getStaffInvitations()
	}, [user])

	return {
		invitation,
		show,
		loading,
		close,
		accept,
		decline
	}
}

export default useStaffInvitations
