import { Store } from 'react-notifications-component'

// Define a type for notification types
type NOTIFICATION_TYPE = 'success' | 'danger' | 'info' | 'default' | 'warning'

export const useNotification = () => {
	// Define a function to notify
	const notify = (type: NOTIFICATION_TYPE, message: string, duration?: number) => {
		Store.addNotification({
			message,
			type,
			insert: 'bottom',
			container: 'bottom-right',
			dismiss: {
				duration: duration ?? 10000,
				click: false,
				touch: false,
				showIcon: true
			}
		})
	}

	return { notify }
}
