// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
// NOTE: components
import Loading from './components/ui/Loading'
// NOTE: routes
import dashboardRoutes from './pages/Index'

const Error = lazy(() => import('./pages/404.js'))
const Auth = React.lazy(() => import('./features/auth/Auth'))

const LoadPage = ({ page }) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const App = () => {
	const routing = useRoutes([
		...dashboardRoutes,
		{
			path: 'auth',
			element: <LoadPage page={<Auth />} />
		},
		{
			path: '*',
			element: <LoadPage page={<Error />} />
		}
	])

	return routing
}

export default App
