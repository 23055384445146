// NOTE: snomed.js file with all the fetches made to the SNOMED API
import {
	bloods,
	physicals,
	staticPhysicals,
	vitalSigns,
	categories
} from '../../utils/snomedConcepts'

// NOTE: get all the concept content based on a concept Id
const getConcept = async (conceptId) => {
	// NOTE: throw error when there isn't a concept ID provided to find children
	if (!conceptId) throw Error('No concept ID provided')

	const response = await fetch(`/api/snomed/concepts/${conceptId}`, {
		method: 'GET'
	})

	return response
}

// NOTE: get all the children of a specific SNOMED concept
const getChildren = async (conceptId) => {
	// NOTE: throw error when there isn't a concept ID provided to find children
	if (!conceptId) throw Error('No concept ID provided')

	const response = await fetch(`/api/snomed/concepts/${conceptId}/children`, {
		method: 'GET'
	})

	return response
}

// NOTE: get all the parents of a specific SNOMED concept
const getParents = async (conceptId) => {
	// NOTE: throw error when there isn't a concept ID provided to find parents
	if (!conceptId) throw Error('No concept ID provided')

	const response = await fetch(`/api/snomed/concepts/${conceptId}/parents`, {
		method: 'GET'
	})

	return response
}

// NOTE: search the SNOMED database base on a text describing the medical concept name and medical tag, e.g.: finding, procedure...
const search = async (searchText, tag) => {
	// NOTE: throw error when the search text is empty
	if (!searchText) throw Error('No snomed concept text provided')

	const response = await fetch(
		`/api/snomed/descriptions?query=${searchText}${tag ? `&semanticFilter=${tag}` : ''}`,
		{
			method: 'GET'
		}
	)

	return response
}

// NOTE: get the human readable text name of a biological sex from its ID
const getBiologicalSexName = (conceptId) => {
	switch (conceptId) {
		case '248153007':
			return 'Male'
		case '248152002':
			return 'Female'
		case '32570691000036108':
			return 'Intersex'
		default:
			return
	}
}

// NOTE: search for a concept in a list of snomed records by its ID
const getConceptById = (conceptFamily, conceptId) =>
	conceptFamily.find((concept) => concept.conceptId === conceptId)

// NOTE: get a specific physical concept by ID from the physicals list
const getPhysicalById = (conceptId) => getConceptById(physicals, conceptId)

// NOTE: get a specific physical concept by ID from the physicals list
const getBloodById = (conceptId) => getConceptById(bloods, conceptId)

// NOTE: get a specific vital sign concept by ID from the vital signs list
const getVitalSignById = (conceptId) => getConceptById(vitalSigns, conceptId)

// NOTE: get a specific static physical (physicals that are only set once, birthday, sex and height)  concept by ID from the static physicals list
const getStaticPhysicalById = (conceptId) => getConceptById(staticPhysicals, conceptId)

export {
	search,
	getParents,
	getConcept,
	getChildren,
	getBiologicalSexName,
	getPhysicalById,
	getBloodById,
	getVitalSignById,
	getStaticPhysicalById,
	categories,
	physicals,
	bloods,
	vitalSigns,
	staticPhysicals
}
